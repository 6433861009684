<template>
  <div id="globalApp">
    <router-view />
  </div>
</template>

<style lang="scss">
/* 全局颜色 */
@import '@/global/scss/global-color.scss';
@import '@/global/scss/element.scss';
/* 公共样式 */
@import '@/assets/css/comm.css';
@import '@/assets/css/layout.css';
@import '@/assets/css/scrollbar.css';

/* 图标 */
@import '@/assets/iconfont/iconfont.css';

#globalApp {
  min-width: 1200px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}

</style>
